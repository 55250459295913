import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

export interface LanguageState {
    value: string;
}

const initialState: LanguageState = {
    value: 'en'
};

export const languageSlice = createSlice({
    name: 'language',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        changeLanguage: (state, action: PayloadAction<string>) => {
            state.value = action.payload;
        },
    }
});

export const { changeLanguage } = languageSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectLanguage = (state: RootState) => state.language.value;

export default languageSlice.reducer;
