import React, { ReactElement } from 'react'
//import { useLocation } from 'react-router-dom';
//import { accountService } from './account.service';
//import { history } from './history';

// interface Props {
//     history: any;
// }

// interface stateType {
//     from: { pathname: string }
// }

export default function Login(): ReactElement {
    //const { state } = useLocation<stateType>();

    // useEffect(() => {
    //     // redirect to home if already logged in
    //     if (accountService.accountValue) {
    //         history.push('/');
    //     }
    // }, [history]);

    return (
        <div className="col-md-6 offset-md-3 mt-5 text-center">
            <div className="card">
                <h4 className="card-header">Please login</h4>
                <div className="card-body">
                    {/* <button className="btn btn-facebook" onClick={accountService.login}>
                        <i className="fa fa-facebook mr-1"></i>
                        Login with Facebook
                    </button> */}
                    <button className="btn btn-facebook">
                        <i className="fa fa-facebook mr-1"></i>
                        Login with Facebook
                    </button>
                </div>
            </div>
        </div>
    )
}
