import React, { ReactElement } from 'react'

// interface Props {

// }

export default function Admin(): ReactElement {
    return (
        <div>
            admin
        </div>
    )
}
