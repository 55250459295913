import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom'
import pic from './DSCF0189.jpg'

function Welcome(): ReactElement {
    const { t } = useTranslation();

    return (
        <div className="container my-5">
            <div className="row p-4 pb-0 pe-lg-0 pt-lg-5 align-items-center rounded-3 border shadow-lg">
                <div className="col-lg-7 p-3 p-lg-5 pt-lg-3">
                    <h1 className="display-4 fw-bold lh-1">{t('FAMILY_MENTORING')}</h1>
                    <p className="lead"></p>
                    <p className="lead">{t('HERO_TEXT1')}</p>
                    <p className="lead">{t('HERO_TEXT2')}</p>
                    <div className="d-grid gap-2 d-md-flex justify-content-md-start mb-4 mb-lg-3">
                        <a className="btn btn-secondary btn-lg px-4" role="button" target="_blank" rel="noreferrer"
                            href={'files/' + t('HOW_TO_BECOME_A_FAMILY_MENTOR_FILENAME')}>{t('HOW_TO_BECOME_A_FAMILY_MENTOR')}</a>
                    </div>
                    <p className="lead">{t('HERO_TEXT3')}</p>
                    <div className="d-grid gap-2 d-md-flex justify-content-md-start mb-4 mb-lg-3">
                        <Link className="btn btn-primary btn-lg px-4 me-md-2 fw-bold" role="button" to="/attitudes">{t('ATTITUDES')}</Link>
                        <Link className="btn btn-primary btn-lg px-4 me-md-2 fw-bold" role="button" to="/competencies">{t('COMPETENCIES')}</Link>
                    </div>
                </div>
                <div className="col-lg-4 offset-lg-1 p-0 overflow-hidden shadow-lg">
                    <img className="rounded-lg-3" src={pic} alt="" width="720"></img>
                </div>
            </div>
        </div>
    )
}

export default Welcome
