import React, { ReactElement } from 'react';
import { Card, Col, Container, Form, Row } from 'react-bootstrap';
import RatingStars from './RatingStars';

interface Props {
    question: any,
    handleAnswer: any
}


function AttitudesQuestion({ question, handleAnswer }: Props): ReactElement {
    if (!question.options)
        return (<div></div>);

    const selectGrade = (value: any) => {
        handleAnswer(value);
    }

    const label1 = question.options.length > 0 ? question.options[0].description : '';
    const label2 = question.options.length > 1 ? question.options[1].description : '';

    return (
        <Form noValidate>
            <Card>
                <Card.Body>
                    <Card.Title className="mb-5">
                        <Container>
                            <Row>
                                <Col xs={6} style={{ overflowWrap: "break-word" }}>{label1}</Col>
                                <Col xs={6} style={{ textAlign: "right", overflowWrap: "break-word" }}>{label2}</Col>
                            </Row>
                        </Container>
                    </Card.Title>
                    <RatingStars onSelectGrade={selectGrade} />
                </Card.Body>
            </Card>
        </Form>
    )
}

export default AttitudesQuestion
