import React, { ReactElement } from 'react'
import { Button, Form, Card } from 'react-bootstrap'

interface Props {
    question: any,
    handleAnswer: any
}

function CompetenciesQuestion({ question, handleAnswer }: Props): ReactElement {
    const selectAnswer = (e: any) => {
        handleAnswer(e.currentTarget.value);
    }

    const renderOptions = () => {
        if (!question.options) {
            return <div></div>
        } else {
            return question.options.map((option: any) => (
                <Button size="lg" variant="outline-primary" key={option.id} onClick={selectAnswer} value={option.number}>
                    {option.description}
                </Button>
            ))
        }
    }

    return (
        <Form noValidate>
            <Card>
                <Card.Body>
                    <Card.Title className="mb-5">{question.number} {question.description}</Card.Title>
                    <Card.Text className="d-grid gap-2">
                        {renderOptions()}
                    </Card.Text>
                </Card.Body>
            </Card>
        </Form>
    )
}

export default CompetenciesQuestion
