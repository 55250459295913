import { useEffect } from 'react';
import { ProgressBar } from 'react-bootstrap';
import { Route, Switch, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { fetchTopic, selectTopic } from '../topics/topicSlice';
import Question from './Question';
import Results from './Results';

type Props = { type: string, topicId: string };

export default function Topic() {
    const { topicId, type } = useParams<Props>();

    const topic = useAppSelector(selectTopic);
    const lan = useAppSelector(state => state.language.value);
    const dispatch = useAppDispatch();

    useEffect(() => {
        const param = { lan: lan, id: +topicId, type: type };
        dispatch(fetchTopic(param))
    }, [dispatch, lan, topicId, type])

    if (!topic) {
        return (
            <section>
                {/* <h2>{t('TOPIC_NOT_FOUND')}</h2> */}
            </section>
        )
    }

    return (
        <section>
            <h2 className='my-5'>{topic.description}</h2>
            <p>{topic.content}</p>
            <ProgressBar className='my-3' now={topic.progress ?? 0} label={`${topic.progress ?? 0}%`} />
            <Switch>
                <Route exact path={`/:type/:topicId`}>
                    <Question topic={topic} />
                </Route>
                <Route path={`/:type/:topicId/questions/:questionId`}>
                    <Question topic={topic} />
                </Route>
                <Route path={`/:type/:topicId/results`}>
                    <Results />
                </Route>
            </Switch>
        </section>
    )
}
