import React, { ReactElement } from 'react';
import styles from './RatingStars.module.css';

interface Props {
    grade: any;
    index: any;
    changeGrade: any;
    selectGrade: any;
    style: any;
    readonly?: boolean;
}

function Star({ grade, index, changeGrade, selectGrade, style, readonly }: Props): ReactElement {
    const handleChangeGrade = (e: any) => {
        if (!readonly) {
            e.preventDefault();
            selectGrade(e.currentTarget.value);
        }
    }

    const handleEnter = (e: any) => {
        if (!readonly) {
            e.preventDefault();
            changeGrade(e.currentTarget.value);
        }
    }

    const handleLeave = (e: any) => {
        if (!readonly) {
            e.preventDefault();
            changeGrade(-1);
        }
    }

    return (
        <label className={styles.star}>
            <button
                className={styles.button}
                name="rating"
                id={grade}
                value={index}
                onMouseEnter={handleEnter}
                onMouseLeave={handleLeave}
                onClick={handleChangeGrade}
            >
                <svg
                    width="46"
                    height="48"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="#393939"
                    strokeWidth=".5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    style={style}
                >
                    {/* <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon> */}
                    <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"></path>
                </svg>
            </button>
        </label>
    )
}

export default Star
