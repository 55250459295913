import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { Bar, Radar } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../app/hooks';
import { selectTopic } from '../topics/topicSlice';

function CompetenciesResults() {
    const { t } = useTranslation();
    const topic = useAppSelector(selectTopic);
    const [chartData, setChartData] = useState({
        datasets: []
    });

    const [chartType, setChartType] = useState(0);

    const colors = [
        "rgba(255,0,0,1)",
        "rgba(255,204,102,1)",
        "rgba(255,255,102,1)",
        "rgba(153,255,51,1)",
        "rgba(62,188,62,1)"
    ];

    useEffect(() => {
        const colors = [
            "rgba(255,0,0,1)",
            "rgba(255,204,102,1)",
            "rgba(255,255,102,1)",
            "rgba(153,255,51,1)",
            "rgba(62,188,62,1)"
        ];

        const data: any = {
            labels: [],
            datasets: []
        };

        if (!topic.questions)
            return;

        var valueSum = 0;
        topic.questions.forEach((question: any, index: number) => {
            const values = new Array(topic.questions.length + 1).fill(0);
            const value = +question.selectedOptionValue;
            valueSum += value;
            values[index] = value;
            const color = value <= colors.length ? colors[value - 1] : "rgba(200,200,255,1)";
            data.labels.push(index + 1);
            data.datasets.push({
                label: (index + 1) + '. ' + question.description,
                data: values,
                backgroundColor: color,
                borderColor: color,
                borderWidth: 5
            });
        });

        // add average
        const values = new Array(topic.questions.length + 1).fill(0);
        const value = Math.round(valueSum / topic.questions.length);
        values[topic.questions.length] = topic.questions.length === 0 ? 0 : value;
        const color = value <= colors.length ? colors[value - 1] : "rgba(200,200,255,1)";
        data.labels.push(topic.questions.length + 1);
        data.datasets.push({
            label: `${(topic.questions.length + 1)}. ${t('AVERAGE_COMPETENCIES_ASSESSMENT').toUpperCase()}`,
            data: values,
            backgroundColor: color,
            borderColor: color,
            borderWidth: 5
        });
        setChartData(data);
    }, [topic, t])

    const options = {
        responsive: false,
        maintainAspectRatio: false,
        ticks: {
            stepSize: 1
        },
        scales: {
            x: {
                ticks: {
                    font: {
                        size: 18
                    },
                },
                pointLabels: {
                    display: true,
                    font: {
                        size: 18
                    }
                },
                display: true,
                title: {
                    display: false,
                    font: {
                        size: 18
                    }
                }
            },
            y: {
                ticks: {
                    font: {
                        size: 18
                    },
                },
                pointLabels: {
                    display: true,
                    font: {
                        size: 18
                    }
                },
                display: true,
                title: {
                    display: false,
                    font: {
                        size: 18
                    }
                },
                min: 0,
                max: 5,
            },
        },
        plugins: {
            legend: {
                display: true,
                position: 'bottom',
                align: 'start',
                labels: {
                    textAlign: 'left',
                    font: {
                        size: 18
                    }
                }
            },
        }
    };

    const radarOptions = {
        responsive: false,
        ticks: {
            stepSize: 1
        },
        scales: {
            r: {
                ticks: {
                    font: {
                        size: 18
                    },
                    z: 999999,
                    display: false
                },
                grid: {
                    circular: true,
                },
                pointLabels: {
                    display: true,
                    font: {
                        size: 18
                    }
                },
                display: true,
                title: {
                    display: false,
                    font: {
                        size: 18
                    }
                },
                min: 0,
                max: 5,
            }
        },
        plugins: {
            legend: {
                display: true,
                position: 'bottom',
                align: 'start',
                labels: {
                    textAlign: 'left',
                    font: {
                        size: 18
                    }
                }
            },
        }
    };

    const selectChartType = (e: any) => {
        setChartType(+e.currentTarget.value);
    }

    return (
        <div className="container">
            <h4>{t('COMPETENCIES_ASSESSMENT_CHART')}</h4>
            <p className="lead"></p>
            {/* <Line options={options} data={chartData} type="line" /> */}
            <Form.Control as="select" aria-label="Chart select" onChange={selectChartType}>
                <option value="0">{t('RADAR_CHART')}</option>
                <option value="1">{t('BAR_CHART')}</option>
            </Form.Control>
            <p className="lead"></p>
            {chartType === 1 ?
                <div className="chartAreaWrapper">
                    <Bar options={options} data={chartData} type="bar" height={680} width={650} />
                </div>
                : null}
            {chartType === 0 ?
                <div className="chartAreaWrapper">
                    <Radar options={radarOptions} data={chartData} type="radar" height={680} width={650} />
                </div>
                : null}
            <p className="lead"></p>
            <div className="container px-4 py-5">
                <h2 className="pb-2 border-bottom">{t('COLOR_EXPLANATION')}</h2>

                <Row className="py-5">
                    <Col className="col-12">
                        <p>
                            <svg className="bi text-muted flex-shrink-0 me-12" width="2.7em" height="1.4em">
                                <rect width="200" height="100" style={{ fill: colors[0] }} />
                            </svg>
                            <span className='mx-2'>{t('COLOR_RED')}</span>
                        </p>
                    </Col>
                    <Col className="col-12">
                        <p>
                            <svg className="bi text-muted flex-shrink-0 me-12" width="2.7em" height="1.4em">
                                <rect width="200" height="100" style={{ fill: colors[1] }} />
                            </svg>
                            <span className='mx-2'>{t('COLOR_ORANGE')}</span>
                        </p>
                    </Col>
                    <Col className="col-12">
                        <p>
                            <svg className="bi text-muted flex-shrink-0 me-12" width="2.7em" height="1.4em">
                                <rect width="200" height="100" style={{ fill: colors[2] }} />
                            </svg>
                            <span className='mx-2'>{t('COLOR_YELLOW')}</span>
                        </p>
                    </Col>
                    <Col className="col-12">
                        <p>
                            <svg className="bi text-muted flex-shrink-0 me-12" width="2.7em" height="1.4em">
                                <rect width="200" height="100" style={{ fill: colors[3] }} />
                            </svg>
                            <span className='mx-2'>{t('COLOR_LIME')}</span>
                        </p>
                    </Col>
                    <Col className="col-12">
                        <p>
                            <svg className="bi text-muted flex-shrink-0 me-12" width="2.7em" height="1.4em">
                                <rect width="200" height="100" style={{ fill: colors[4] }} />
                            </svg>
                            <span className='mx-2'>{t('COLOR_GREEN')}</span>
                        </p>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default CompetenciesResults
