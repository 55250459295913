import React from 'react';
import {
  BrowserRouter as Router, Redirect, Route, Switch
} from 'react-router-dom';
import './App.css';
import Admin from './features/admin/Admin';
import SiteHeader from './features/header/SiteHeader';
import Home from './features/home/Home';
import Login from './features/login/Login';
import Topic from './features/topics/Topic';
import Topics from './features/topics/Topics';
import SiteFooter from './features/welcome/SiteFooter';
import Welcome from './features/welcome/Welcome';

function App() {
  return (
    <Router>
      <SiteHeader />
      <div className="App">
        <Switch>
          <Route path="/admin" component={Admin} />
          <Route path="/login" component={Login} />
          {/* <Menu /> */}
          <Route path="/welcome" component={Welcome} />
          <Route path="/:type/:topicId" component={Topic} />
          <Route path="/:type" component={Topics} />
          <Route exact path="/" component={Home} />
          <Redirect to="/" />
        </Switch>
      </div>
      <SiteFooter />
    </Router>
  );
}

export default App;
