import React, { ReactElement } from 'react';
import { Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../app/hooks';
import { selectTopic } from '../topics/topicSlice';
import RatingStars from './RatingStars';

function AttitudesResults(): ReactElement {
    const topic = useAppSelector(selectTopic);
    const { t } = useTranslation();

    // https://stackoverflow.com/a/38327540
    const groupBy = (list: any, keyGetter: any) => {
        const map = new Map();

        if (!list)
            return map;

        list.forEach((item: any) => {
            const key = keyGetter(item);
            const collection = map.get(key);
            if (!collection) {
                map.set(key, [item]);
            } else {
                collection.push(item);
            }
        });
        return map;
    }

    const grouped = groupBy(topic.questions, (e: any) => {
        return e.group.no + "|" + e.group.description + "|" + e.group.text;
    });

    const renderAnswers = () => {
        return Array.from(grouped).map(([key, value]) => {
            const groupValue = key.split("|");

            return (<React.Fragment key={groupValue[0]}>
                <tr>
                    <th colSpan={3}><h3>{groupValue[1]}</h3></th>
                </tr>
                {
                    value.map((e: any) => (
                        <tr key={e.id}>
                            <td>{e.options.length > 0 ? e.options[0].description : ''}</td>
                            <td><RatingStars grade={e.selectedOptionValue} readonly={true} /></td>
                            <td>{e.options.length > 1 ? e.options[1].description : ''}</td>
                        </tr>
                    ))
                }
                <tr>
                    <td colSpan={3}>{t('QUESTION_FOR_SELF_REFLECTION')}: {groupValue[2]}</td>
                </tr>
            </React.Fragment>
            )
        });
    }

    return (
        <div>
            <p className="lead">{t('ATTITUDES_RESULT_EXPLANATION')}</p>
            <p className="lead">{t('ATTITUDES_RESULT_EXPLANATION2')}</p>
            <Table bordered hover size="sm" responsive>
                <tbody>
                    {renderAnswers()}
                </tbody>
            </Table>
        </div>
    )
}

export default AttitudesResults
