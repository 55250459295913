import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios';

const initialState = {
    items: [],
    status: 'idle',
    error: null
};

const client = axios.create({
    baseURL: "https://api.skudutiskis.lt/api"
});

export const fetchTopics = createAsyncThunk('topics/fetchTopics', async (lan: string) => {
    const response = await client.get(`/${lan}/topics/competencies`)
    return response.data
})

const topicsSlice = createSlice({
    name: 'topics',
    initialState,
    reducers: {

    },
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    extraReducers: (builder) => {
        builder
            .addCase(fetchTopics.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchTopics.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.items = action.payload;
            });
    },
})

export default topicsSlice.reducer

export const selectAllTopics = (state: { topics: any; }) => {
    return state.topics.items;
}