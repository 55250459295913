import React from 'react';
//@ts-ignore
import ReactCountryFlag from "react-country-flag";
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../app/hooks';
import { changeLanguage } from './languageSlice';

const LangSelector = () => {
    const { i18n } = useTranslation();
    const dispatch = useAppDispatch();

    const onChangeLanguage = (lan: string) => {
        i18n.changeLanguage(lan);
        dispatch(changeLanguage(lan));
    }

    return (
        <div>
            <ReactCountryFlag className="mx-1" role="button" onClick={() => onChangeLanguage('lt')}
                countryCode="LT"
                svg
                style={{
                    width: '2em',
                    height: '2em',
                }}
                title="LT"
            />
            <ReactCountryFlag className="mx-1" role="button" onClick={() => onChangeLanguage('en')}
                countryCode="GB"
                svg
                style={{
                    width: '2em',
                    height: '2em',
                }}
                title="GB"
            />
            <ReactCountryFlag className="mx-1" role="button" onClick={() => onChangeLanguage('fr')}
                countryCode="FR"
                svg
                style={{
                    width: '2em',
                    height: '2em',
                }}
                title="FR"
            />
            <ReactCountryFlag className="mx-1" role="button" onClick={() => onChangeLanguage('ee')}
                countryCode="EE"
                svg
                style={{
                    width: '2em',
                    height: '2em',
                }}
                title="EE"
            />
            <ReactCountryFlag className="mx-1" role="button" onClick={() => onChangeLanguage('pl')}
                countryCode="PL"
                svg
                style={{
                    width: '2em',
                    height: '2em',
                }}
                title="PL"
            />
            <ReactCountryFlag className="mx-1" role="button" onClick={() => onChangeLanguage('es')}
                countryCode="ES"
                svg
                style={{
                    width: '2em',
                    height: '2em',
                }}
                title="ES"
            />
        </div>
    )
}

export default LangSelector;
