import React, { ReactElement, useEffect, useState } from 'react';
import styles from './RatingStars.module.css';
import Star from './Star';

interface Props {
    grade?: any;
    onSelectGrade?: any;
    readonly?: boolean;
}

function RatingStars({ grade, onSelectGrade, readonly }: Props): ReactElement {
    const GRADES = ['1', '2', '3', '4', '5'];
    const [gradeIndex, setGradeIndex] = useState(-1);
    const changeGradeIndex = (index: any) => {
        setGradeIndex(index);
    }

    useEffect(() => {
        const index = ['1', '2', '3', '4', '5'].indexOf(grade);
        setGradeIndex(index);
    }, [grade])

    const selectGrade = (index: any) => {
        if (!readonly) {
            setTimeout(
                () => {
                    if (onSelectGrade) {
                        setGradeIndex(-1);
                        onSelectGrade(GRADES[index]);
                    }
                },
                100
            );
        }
    }

    const activeStar = {
        fill: '#fdb913'
    };

    return (
        <div>
            {/* <h1 className={styles.result}></h1> */}
            <div className={styles.stars}>
                {
                    GRADES.map((grade, index) => (
                        <Star
                            index={index}
                            grade={grade}
                            changeGrade={changeGradeIndex}
                            selectGrade={selectGrade}
                            key={grade}
                            readonly={readonly}
                            style={+gradeIndex === index ? activeStar : {}}
                        />
                    ))
                }
            </div>
        </div>
    )
}

export default RatingStars
