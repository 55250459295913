import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom'
import LanguageSelector from '../language/LanguageSelector'
import logo from './Logo_FM.jpg'

function SiteHeader(): ReactElement {
    const { t } = useTranslation();

    return (
        <div className="container">
            <header className="d-flex flex-wrap justify-content-center py-3 mb-4 border-bottom">
                {/* <a href="/" className="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-dark text-decoration-none">
                    <svg className="bi me-2" width="40" height="32"><use xlinkHref="#bootstrap"></use></svg>
                    <span className="fs-4">Simple header</span>
                </a> */}
                <Link className="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-dark text-decoration-none" role="button" to="/">
                    <img style={{ marginRight: 20 }} width="40" height="40" src={logo} alt="Logo" />
                    <span className="fs-4">{t('APP_TITLE')}</span>
                </Link>
                <LanguageSelector />
                <ul className="nav nav-pills">
                    {/* <li className="nav-item"><a href="#" className="nav-link active" aria-current="page">Competencies</a></li>
                    <li className="nav-item"><a href="#" className="nav-link">Features</a></li> */}
                </ul>
            </header>
        </div>
    )
}

export default SiteHeader
