import React, { ReactElement } from 'react'
import { useParams } from 'react-router-dom';
import AttitudesResults from '../attitudes/AttitudesResults'
import CompetenciesResults from '../competencies/CompetenciesResults'

type Props = { type: string };

function Results(): ReactElement {
    let { type } = useParams<Props>();

    if (type === 'attitudes')
        return (
            <AttitudesResults />
        )

    return (
        <CompetenciesResults />
    )
}

export default Results
