import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import AttitudesQuestion from '../attitudes/AttitudesQuestion';
import CompetenciesQuestion from '../competencies/CompetenciesQuestion';
import TopicModel from './TopicModel';
import { selectOption, selectUnansweredQuestions } from './topicSlice';

type Props = { questionId: string };

interface QuestionProps {
    topic: TopicModel;
}

function Question({ topic }: QuestionProps) {
    const [question, SetQuestion] = useState<any | null>(null);
    const history = useHistory();
    const dispatch = useAppDispatch();

    let { questionId } = useParams<Props>();

    const unansweredQuestions = useAppSelector(selectUnansweredQuestions);

    useEffect(() => {
        if (questionId) {
            SetQuestion(getQuestionFromTopic(topic, +questionId));
        } else {
            SetQuestion(getFirstQuestionInTopic(topic));
        }
    }, [questionId, topic, unansweredQuestions]);

    const handleAnswer = (selectedOptionValue: any) => {
        const param = { questionId: question.id, value: selectedOptionValue };
        dispatch(selectOption(param));

        const nextQuestion = getNextUnansweredQuestion(unansweredQuestions, question.id);

        if (nextQuestion) {
            history.push(`/${topic.type}/${topic.id}/questions/${nextQuestion.id}`);
        } else {
            history.push(`/${topic.type}/${topic.id}/results`);
        }
    }

    const getQuestionFromTopic = (topic: TopicModel, questionId: number): any => {
        if (topic.questions && topic.questions.length > 0) {
            return topic.questions.find((question: any) => question.id === +questionId);
        }
    }

    const getNextUnansweredQuestion = (arr: Array<any>, questionId: number): any => {
        const unanswered = arr.filter((e: any) => e.id !== questionId);

        if (unanswered.length > 0) {

            if (topic.type === 'attitudes')
                // return in random order
                return arr[Math.floor(Math.random() * arr.length)];

            return unanswered[0];
        }
    }

    const getFirstQuestionInTopic = (topic: TopicModel): any => {
        if (topic.questions && topic.questions.length > 0) {
            if (topic.type === 'attitudes')
                // return in random order
                return topic.questions[Math.floor(Math.random() * topic.questions.length)];

            return topic.questions[0];
        }
    }

    if (!question) {
        return (
            <div />
        )
    }

    if (topic.type === 'attitudes')
        return (
            <AttitudesQuestion question={question} handleAnswer={handleAnswer} />
        )

    return (
        <CompetenciesQuestion question={question} handleAnswer={handleAnswer} />
    )
}

export default Question

