import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios';

type TopicState = {
    item: any,
    status: 'idle' | 'loading' | 'succeeded' | 'failed'
    error: any,
    value: number
}

const initialState: TopicState = {
    item: null,
    status: 'idle',
    error: null,
    value: 0
};

interface Param {
    lan: string, id: number, type: string
};

const client = axios.create({
    baseURL: "https://api.skudutiskis.lt/api"
});

export const fetchTopic = createAsyncThunk('topics/fetchTopic', async (param: Param) => {
    const response = await client.get(`/${param.lan}/topics/${param.type}/${param.id}`)
    return response.data
})

const topicSlice = createSlice({
    name: 'topic',
    initialState,
    reducers: {
        selectOption(state: any, action) {
            const question = state.item.questions.find((e: any) => e.id === action.payload.questionId);
            question.selectedOptionValue = action.payload.value;

            const length = state.item.questions.length ?? 1;
            const answered = state.item.questions.filter((e: any) => e.selectedOptionValue ? true : false).length;
            state.item.progress = (Math.round(answered / length * 100));
        }
    },

    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    extraReducers: (builder) => {
        builder
            .addCase(fetchTopic.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchTopic.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.item = action.payload;
            });
    },
})

export default topicSlice.reducer

export const { selectOption } = topicSlice.actions

export const selectTopic = (state: { topic: TopicState; }) => {
    return state.topic.item;
}

export const selectUnansweredQuestions = (state: { topic: TopicState; }) => {
    if (state.topic.item.questions)
        return state.topic.item.questions.filter((e: any) => e.selectedOptionValue ? false : true);

    return [];
}