import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import attitudesSlice from '../features/attitudes/attitudesSlice';
import counterReducer from '../features/counter/counterSlice';
import languageSlice from '../features/language/languageSlice';
import topicSlice from '../features/topics/topicSlice';
import topicsSlice from '../features/topics/topicsSlice';

export const store = configureStore({
  reducer: {
    attitudes: attitudesSlice,
    counter: counterReducer,
    language: languageSlice,
    topics: topicsSlice,
    topic: topicSlice
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
