import React, { useEffect } from 'react';
import { ListGroup, ProgressBar } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { LinkContainer } from 'react-router-bootstrap';
import { useAppSelector } from '../../app/hooks';
import { fetchTopics, selectAllTopics } from './topicsSlice';


export const Competencies = () => {
    const { t } = useTranslation();

    const dispatch = useDispatch()

    const status = useAppSelector(state => state.topics.status)

    const topics = useAppSelector(selectAllTopics);

    const lan = useAppSelector(state => state.language.value);

    useEffect(() => {
        //if (status === 'idle') {
        dispatch(fetchTopics(lan))
        // }
    }, [lan, dispatch])

    const renderedQuestionnaires = topics.map((topic: any) => (
        <LinkContainer to={`/competencies/${topic.id}`} key={topic.id}>
            <ListGroup.Item action>
                {topic.number} {topic.description}
            </ListGroup.Item>
        </LinkContainer>
    ))

    return (
        <div className="container my-5">
            <div className="row p-4 pb-0 pe-lg-0 pt-lg-5 align-items-center rounded-3 border shadow-lg">
                <div className="col-lg-7 p-3 p-lg-5 pt-lg-3">
                    <h1 className="display-4 fw-bold lh-1">{t('CHOOSE_AN_AREA_OF_COMPETENCIES')}</h1>
                    <p className="lead"></p>
                    <div className="lead">
                        <ListGroup variant="flush">
                            {status === 'loading' ? <ProgressBar animated now={100} /> : renderedQuestionnaires}
                        </ListGroup>
                    </div>
                </div>
            </div>
        </div>
    )
}

