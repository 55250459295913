import React, { ReactElement, useEffect } from 'react';
import { ProgressBar } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { useAppSelector } from '../../app/hooks';
import { fetchAttitudes, selectAllAttitudes } from './attitudesSlice';

function Attitudes(): ReactElement {

    const { t } = useTranslation();

    const dispatch = useDispatch()

    const status = useAppSelector(state => state.attitudes.status)

    const attitudes = useAppSelector(selectAllAttitudes);

    const lan = useAppSelector(state => state.language.value);

    useEffect(() => {
        if (status === 'idle') {
            dispatch(fetchAttitudes(lan))
        }
    }, [status, lan, dispatch])

    const renderedQuestionnaires = attitudes.map((topic: any) => (
        <div className="d-grid gap-2 d-md-flex justify-content-md-start mb-4 mb-lg-3" key={topic.id}>
            <Link className="btn btn-primary btn-lg px-4 me-md-2 fw-bold" role="button" to={`/attitudes/${topic.id}`}>{t('START_FILLING')}</Link>
        </div>
    ))

    return (
        <div className="container my-5">
            <div className="row p-4 pb-0 pe-lg-0 pt-lg-5 align-items-center rounded-3 border shadow-lg">
                <div className="col-lg-7 p-3 p-lg-5 pt-lg-3">
                    <h1 className="display-4 fw-bold lh-1">{t('EVALUATION_OF_PERSONAL_ATTITUDES')}</h1>
                    <p className="lead"></p>
                    <p className="lead">{t('INSTRUCTIONS_FOR_FILLIG_IN_THE_TABLE_TEXT1')}</p>
                    <p className="lead">{t('INSTRUCTIONS_FOR_FILLIG_IN_THE_TABLE_TEXT2')}</p>
                    {status === 'loading' ? <ProgressBar animated now={100} /> : renderedQuestionnaires}
                </div>
            </div>
        </div>
    )
}

export default Attitudes
