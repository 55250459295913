import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios';

const initialState = {
    items: [],
    status: 'idle',
    error: null
};

const client = axios.create({
    baseURL: "https://api.skudutiskis.lt/api"
});

export const fetchAttitudes = createAsyncThunk('attitudes/fetchAttitudes', async (lan: string) => {
    const response = await client.get(`/${lan}/topics/attitudes`)
    return response.data
})

const attitudesSlice = createSlice({
    name: 'attitudes',
    initialState,
    reducers: {

    },
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    extraReducers: (builder) => {
        builder
            .addCase(fetchAttitudes.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchAttitudes.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.items = action.payload;
            });
    },
})

export default attitudesSlice.reducer

export const selectAllAttitudes = (state: { attitudes: any; }) => {
    return state.attitudes.items;
}