import React, { ReactElement } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import styles from './Home.module.css'
import erasmus_logo from './erasmus_logo.png'
import logo_Fass from './logo_Fass.png'
import logo_INFODEF from './logo_INFODEF.png'
import logo_paysloire from './logo_paysloire.png'
import logo_SkA from './logo_SkA.png'
import logo_Vestifex from './logo_Vestifex.jpg'
import logo_VSPC from './logo_VSPC.png'
import FM_Logo_color from './FM_Logo_color.jpg'
import { Link } from 'react-router-dom'

function Home(): ReactElement {
    const { t } = useTranslation();

    return (
        <div className="container my-5 text-center">
            <div className="col-md-2 mb-4 mx-auto">
                <img className="mx-auto mb-4" src={FM_Logo_color} alt="Family mentor" style={{ maxWidth: '100%' }}></img>
            </div>
            <h1 className="display-5 fw-bold">{t('PROJECT_TITLE')}</h1>
            <h4 className="my-4">{t('PROJECT_DESCRIPTION')}</h4>
            <div className="col-lg-12 mx-auto mb-4" style={{ marginTop: '6rem' }}>
                <div className="d-grid gap-2 d-sm-flex justify-content-sm-center">
                    <Link className="btn btn-primary btn-lg px-4 me-md-2 fw-bold" role="button" to="/welcome">{t('START')}</Link>
                </div>
            </div>
            <Container fluid>
                <Row className={styles.logos} style={{ marginTop: '6rem' }}>
                    <Col className="mb-4" md={2}><a href="https://skudutiskis.lt/" target="_blank" rel="noreferrer"><img src={logo_SkA} style={{ maxHeight: '110px' }} alt=""></img></a></Col>
                    <Col className="mb-4" md={2}><a href="https://www.fundacjafass.pl/" target="_blank" rel="noreferrer"><img src={logo_Fass} alt=""></img></a></Col>
                    <Col className="mb-4" md={2}><a href="http://infodef.es/" target="_blank" rel="noreferrer"><img src={logo_INFODEF} style={{ maxHeight: '100px' }} alt=""></img></a></Col>
                    <Col className="mb-4" md={2}><a href="https://pays-de-la-loire.famillesrurales.org/" target="_blank" rel="noreferrer"><img src={logo_paysloire} style={{ maxHeight: '110px' }} alt=""></img></a></Col>
                    <Col className="mb-4" md={2}><a href="https://www.seimospsichologija.lt/" target="_blank" rel="noreferrer"><img src={logo_VSPC} alt=""></img></a></Col>
                    <Col className="mb-4" md={2}><a href="https://vestifex.com/" target="_blank" rel="noreferrer"><img src={logo_Vestifex} alt=""></img></a></Col>
                </Row>
                <Row style={{ marginTop: '4rem' }}>
                    <Col md={3}><img className="my-4" src={erasmus_logo} alt="" style={{ maxWidth: '100%' }}></img></Col>
                    <Col md={9}><p className="lead my-4" style={{ textAlign: 'left' }}>{t('PUBLICATION')}</p></Col>
                </Row>
            </Container>
        </div >
    )
}

export default Home
