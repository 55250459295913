import React, { ReactElement, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Attitudes from '../attitudes/Attitudes';
import { Competencies } from './Competencies';

type Props = { type: string };

function Topics(): ReactElement {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    let { type } = useParams<Props>();

    if (type === 'attitudes') {
        return (
            <Attitudes />
        )
    }

    if (type === 'competencies') {
        return (
            <Competencies />
        )
    }

    return (
        <div>

        </div>
    )
}

export default Topics
